<template>
  <div id="vital1" class="company_content">
    <div class="index1">
      <div class="foot"></div>
      <div class="youba">YOUBA</div>
      <div class="youba_desc">{{ desc }}</div>
      <div class="about">
        <div class="about_youba"><span>关于</span>中国游巴网</div>
        <div class="about_desc">
          多平台联通、平台数据共享，源头管理、风险预警、网上执法
        </div>
        <div class="about_desc">
          智能化管理系统，实现精细管理、降本增效、多元增值、安全生产等功能
        </div>
        <el-button @click="dialogVisible = true">联系我们</el-button>
      </div>
    </div>
    <div class="index2">
      <img :src="culture.imgUrl" alt="" />
      <div class="culture_box">
        <div class="culture_title">企业文化</div>
        <div class="line"></div>
        <div class="culture_desc">
          {{ culture.desc }}
        </div>
      </div>
    </div>
    <img :src="developmentUrl" alt="" class="development" />
    <el-dialog
      :visible.sync="dialogVisible"
      width="23.5%"
      :show-close="false"
      custom-class="dialog_body"
      top="25vh"
    >
      <div class="dialog_title">联系我们</div>
      <div class="dialog_desc dialog_ma">400-800-3550（服务热线）</div>
      <div class="dialog_desc">0311-85291868（石家庄）</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  components: {},
  data() {
    return {
      dialogVisible: false,
      desc: "“中国游巴网”（www.chinaU8.com）成立于2014年3月，是集行业监管、企业管理和社会化服务“三位一体”的综合性公共服务平台。具有“游巴监管、游巴大数据、游巴车管家、游巴集采、游巴出行、游巴商城”六大系统；为公众提供多样化的安全出行服务、完善的诚信评价与客诉体系，实现车源合法、便捷订车、价格透明。",
      culture: {
        imgUrl:
          "https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/youba1.png",
        desc: "中国游巴网先进的理念在实践中形成了极具特色的企业文化；凝聚诚信精神、创新精神、职业精神和执行精神为核心的游巴精神。关于未来的发展，中国游巴网创始人吴华表示，作为行业的一个新标杆，我们深信成功的背后离不开政府、用户对我们的信赖与支持。平台未来将会继续前进的步伐，在三年内完成全国2/3旅游城市的覆盖，向“打造包车客运数字化服务第一品牌”的目标不断迈进。",
      },
      developmentUrl:
        "https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/development.png",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.company_content {
  background-color: white;
}
.index1 {
  height: 100vh;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/about_bg.png");
  background-size: 100% 100%;
  text-align: left;
  position: relative;
  // padding-left: 340px;
  .about {
    position: absolute;
    left: 130px;
    top: 210px;
    .about_youba {
      font-size: 48px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #cd861c;
      line-height: 56px;
      margin-bottom: 8px;
      span {
        color: #ffffff;
      }
    }
    .about_desc {
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
      &:first-child {
        margin-top: 17px;
      }
    }
  }
  .foot {
    width: 763px;
    height: 348px;
    background: url("~@/assets/aboutNew/bg_foot.png");
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .youba {
    position: absolute;
    right: 550px;
    bottom: 230px;
    font-size: 42px;
    font-family: Alegreya-Bold, Alegreya;
    font-weight: bold;
    color: #ffffff;
    line-height: 49px;
  }
  .youba_desc {
    position: absolute;
    right: 60px;
    bottom: 50px;
    width: 642px;
    height: 152px;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
  }
}
.el-button {
  margin-top: 8px;

  color: #217eff;
}

.index2 {
  width: 1500px;
  padding: 90px 210px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  .culture_box {
    .culture_title {
      width: 128px;
      height: 64px;
      font-size: 32px;
      font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
      font-weight: 800;
      color: #333333;
      line-height: 64px;
    }
    .line {
      width: 156px;
      height: 4px;
      background: #295bdd;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .culture_desc {
      width: 870px;
      height: 315px;
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 60px;
      text-align: left;
      text-indent: 48px;
      margin-top: 45px;
    }
  }

  img {
    width: 600px;
    height: 400px;
    margin-right: 40px;
  }
}
.development {
  width: 100%;
  height: 100%;
}
.dialog_title {
  font-size: 32px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #000000;
  line-height: 38px;
  margin-bottom: 35px;
}
.dialog_desc {
  font-size: 24px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #295bdd;
}

.dialog_ma {
  margin-bottom: 20px;
}
.dialog_body {
  border-radius: 24px !important;
}
.el-dialog__body {
  border-radius: 24px 24px 24px 24px;
}
.el-dialog__header {
  display: none;
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
